<template lang="pug">
Loading
.maincontain
  GroupModal(
    ref="modal"
    v-if="isShowArtistModal"
    :groupId="selectedArtist.group_id"
    @closeModal='closeModal'
    @toPrevGroup='toPrevGroup'
    @toNextGroup='toNextGroup'
  )
  .viewCircle(ref="viewCircle")
    .viewCircle__block.flex-center(
      :class="{'viewCircle--show': isViewCircleShow,'viewCircle--close': isViewCircleClose, 'viewCircle--move': isViewCircleMove,'viewCircle--moveStop':isViewCircleMoveStop }"
    )
      .viewCircle__block__text view more
  .main.flex-center.flex-column
    img.bg.bg--line(src="@/assets/images/bg-right.png")
    img.bg.bg--geer(src="@/assets/images/bg-geer.png")
    video.bg.bg--sky(playsinline autoplay muted loop)
      source(src='/static/sky2Clip.mov' type="video/mp4")
    img.bg.bg--mental(src="@/assets/images/bg-leftTop.png")
    img.bg.bg--mobile(src="@/assets/images/artistroom-md-bg.png")
    .d-flex
      .main__btn.d-inline-flex(:class="{'main__btn--active' : isSelectExh, 'main__btn--hover': isSelectExh&&isHoverExh,'main__btn--wordchang':!isSelectExh&&isHoverExh}"  @click="isSelectExh = true;isHoverExh=false;" @mousemove="isSelectExh? isHoverExh = false : isHoverExh = true" @mouseleave="isSelectExh? isHoverExh = false : isHoverExh = false")
        img.main__btn__img(:src="require(`@/assets/images/banner-arrow.svg`)")
        .main__btn__text.main__btn__text--space {{ $t('shared.artwork') }}

      .main__btn.d-inline-flex(
        :class="{'main__btn--active': !isSelectExh, 'main__btn--hover': !isSelectExh&&isHoverExh,'main__btn--wordchang':isSelectExh&&isHoverExh}"
        @click="isSelectExh = false; isHoverExh=false;" @mousemove="!isSelectExh? isHoverExh = false : isHoverExh = true"
        @mouseleave="!isSelectExh? isHoverExh = false : isHoverExh = false")
        img.main__btn__img(:src="require(`@/assets/images/banner-arrow.svg`)")
        .main__btn__text.main__btn__text--en {{ $t('shared.futureLabVision') }}
    .main__content(v-if="isSelectExh") {{ $t('artistRoom.exhTitle') }}
    .main__content(v-else) {{ $t('artistRoom.nonExhTitle') }}
  .artistBlock--margin
    .container-fluid
      .row(v-if="artistList.length>0")
        .col-sm-12.col-md-6.col-lg-3(v-for="(item,index) in filteredGroups" :key="index")
          .artistBlock(
            @mouseenter="showMoreCircle(true)"
            @mousemove="moveMoreCircle($event)"
            @mouseleave="showMoreCircle(false)"
            @click="isShowArtistModal = true; selectedArtist = item"
            :style="{backgroundImage:`url(${$getPhotoUrl(item.photo)})`}"
          )
            .artistBlock__img
              //- img.img-fluid(:src="require(`@/assets/images/artist-example.png`)")
            .artistBlock__data
              .artistBlock__label.artistBlock__label--bold {{ item.group_zh.name }}
              .artistBlock__label(v-if="item.group_zh.name !== item.group_en.name") {{ item.group_en.name }}
      //- .row
      //-   .col-sm-12.col-md-6.col-lg-3(v-for="(item,index) in artistList" :key="index")
      //-     .artistBlock(
      //-       @mouseenter="showMoreCircle(true)"
      //-       @mousemove="moveMoreCircle($event);"          @mouseleave="showMoreCircle(false);"
      //-       @click.stop="isShowArtistModal = true"
      //-       :style="{backgroundImage:`url(${require('@/assets/images/1.png')})`}"
      //-     )
      //-       .artistBlock__img
      //-         //- img.img-fluid(:src="require(`@/assets/images/1.png`)")
      //-       .artistBlock__data
      //-         .artistBlock__label.artistBlock__label--bold {{ item.name }}
      //-         .artistBlock__label {{ item.en }}
      //- .row
      //-   .col-sm-12.col-md-6.col-lg-3(v-for="(item,index) in artistList" :key="index")
      //-     .artistBlock(@mouseenter="showMoreCircle(true)" @mousemove="moveMoreCircle($event)" @mouseleave="showMoreCircle(false)" @click="isShowArtistModal = true" :style="{backgroundImage:`url(${require('@/assets/images/bg-sky.png')})`}")
      //-       .artistBlock__img
</template>

<script>
// import ArtistModal from "@/component/ArtistModal.vue";
import GroupModal from "@/component/GroupModal.vue";

import { getGroups, getGroup } from "@/api/group";
import Loading from "@/component/Loading";

export default {
  name: "artists-room",
  components: {
    GroupModal,
    Loading
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_BASE_API,
      artistList: [{ locations: { exh: false, fvl: false } }],
      isViewCircleShow: false,
      isViewCircleMove: false,
      isViewCircleLeave: false,
      isViewCircleMoveStop: false,
      isSelectExh: true,
      isHoverExh: false,
      isShowArtistModal: false,
      isViewCircleClose: false,
      mouseStartedMoving: false,
      mouseMoved: false,
      detectMouse: null,
      selectedArtist: {},
    };
  },
  mounted() {
    this.updateGroups();
  },
  methods: {
    async updateGroups() {
      let tempList = await getGroups();

      tempList.forEach((group) => {
        group.locations = {
          fvl: false,
          exh: false,
        };
        group.category.forEach((c) => {
          if (c.name_en.toLowerCase().includes("fvl"))
            group.locations.fvl = true;
          if (c.name_en.toLowerCase().includes("artworks"))
            group.locations.exh = true;
        });
      });

      this.artistList = tempList;
    },
    toPrevGroup() {
      const index = this.currentGroupIndex;
      if (index === 0)
        this.selectedArtist =
          this.filteredGroups[this.filteredGroups.length - 1];
      if (index !== 0) this.selectedArtist = this.filteredGroups[index - 1];
      this.$refs.modal.getGroupData(this.selectedArtist.group_id);
    },
    toNextGroup() {
      const index = this.currentGroupIndex;
      if (index === this.filteredGroups.length - 1)
        this.selectedArtist = this.filteredGroups[0];
      if (index !== this.filteredGroups.length - 1)
        this.selectedArtist = this.filteredGroups[index + 1];
      this.$refs.modal.getGroupData(this.selectedArtist.group_id);
    },
    setIntervalMouse() {
      // console.log('this.isViewCircleLeave',this.isViewCircleLeave)
      // console.log('this.isViewCircleMoveStop',this.isViewCircleMoveStop)
      // console.log('this.mouseStartedMoving',this.mouseStartedMoving)
      // console.log('this.isViewCircleLeave',this.isViewCircleLeave)
      if (!this.mouseStartedMoving && !this.isViewCircleLeave) {
        //Mouse stopped moving
        //Do CSS change
        // console.log('Mouse stopped moving')
        this.mouseStartedMoving = false;
        this.isViewCircleMove = false;
        this.isViewCircleMoveStop = true;
        // this.isViewCircleShow = true;
      } else {
        // console.log('Mouse moving')
        this.isViewCircleMoveStop = false;
      }
      this.mouseStartedMoving = false;
    },
    showMoreCircle(isShow) {
      // console.log('進showMoreCircle fn')
      // console.log("isShow",isShow)
      // if(isShow !== this.isViewCircleShow){
      if (isShow) {
        // console.log('detectMouse',this.detectMouse)
        this.detectMouse = setInterval(this.setIntervalMouse, 100);
        this.isViewCircleLeave = false;
        this.isViewCircleShow = true;
        this.isViewCircleClose = false;
      } else {
        // console.log('detectMouse',this.detectMouse)
        // console.log('leave')
        clearInterval(this.detectMouse);
        this.detectMouse = null;
        this.isViewCircleLeave = true;
        this.isViewCircleShow = false;
        this.isViewCircleClose = true;
      }
      // }
    },
    moveMoreCircle($event) {
      // console.log($event)
      this.isViewCircleShow = false;
      this.isViewCircleMove = true;
      this.mouseStartedMoving = true;
      this.mouseMoved = true;
      this.$refs.viewCircle.style.top =
        $event.clientY - this.$refs.viewCircle.offsetHeight / 2 + "px";
      this.$refs.viewCircle.style.left =
        $event.clientX - this.$refs.viewCircle.offsetWidth / 2 + "px";
    },
    closeModal() {
      this.isShowArtistModal = this.isShowArtistModal !== true;
    },
  },
  computed: {
    currentGroupIndex() {
      return this.filteredGroups.indexOf(this.selectedArtist);
    },
    filteredGroups() {
      if (this.isSelectExh)
        return this.artistList.filter((a) => a.locations.exh);
      return this.artistList.filter((a) => a.locations.fvl);
    },
  },
  setup() {},
};
</script>

<style lang="sass" scoped>
// .container, .container-fluid
//   padding: 0
//   margin: 0
.modal--center
  z-index: 1000
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0

.bg
  position: fixed
  z-index: -1

  &--sky
    object-fit: cover
    width: 100%
    height: auto
    top: 58px
    left: 0
    max-width: 865px
    max-height: 382px
    +rwd-md
      z-index: -2
      top: 49px
      width: 92%

  &--mental
    top: 0
    left: -33px
    width: auto
    +rwd-md
      width: 42%
      top: 51px
      left: -7px

  &--line
    top: 56px
    right: 0
    width: auto
    min-height: 488px
    display: block

    +rwd-md
      display: none
      top: 25%
      width: 71%
      min-height: auto
      right: -31%

  &--geer
    top: 56px
    right: 0
    width: auto
    min-height: 488px
    display: inline
    +rwd-md
      display: none
      top: 25%
      width: 71%
      min-height: auto
      right: -31%

  &--mobile
    right: 0
    display: none
    +rwd-md
      display: inline
      top: 94px


.main
  margin-top: 57px
  padding: 160px 20px 65px 20px
  width: 100%
  position: relative
  +rwd-sm
    margin-top: 63px
    padding: 51px 7px 58px 7px

  &__content
    font-family: "Noto Sans TC", Arial, Helvetica, sans-serif
    margin-top: 100px
    max-width: 632px
    font-size: 14px
    line-height: 24px
    +rwd-md
      margin-top: 25px
      padding: 0 4%
    +rwd-sm
      margin-top: 25px
      padding: 0 12px

  &__btn
    transition: 0.5s cubic-bezier(.76, 0, .24, 1)
    max-height: 86px

    & > *
      transition: 0.5s cubic-bezier(.76, 0, .24, 1)
    margin: 0 10px
    border: 1px solid #000
    position: relative
    cursor: pointer
    background-color: #fff
    padding: 20px 40px 20px 22px
    align-items: center
    flex-wrap: nowrap
    flex-grow: 0

    +rwd-md
      margin: 0 4px
      padding: 15px 15px 15px 15px

    &:hover
      background-color: #000

    &:hover > .main__btn__text
      color: #fff
      flex-grow: 1

    &:hover > .main__btn__line
      width: 0
      opacity: 0

    &:hover ~ .main__btn--active
      background-color: #fff
      // max-width: 381px

    &--active
      flex-grow: 1
      background-color: #000

    &--active > .main__btn__img
      display: inline
      // opacity: 1
      // width: 100%
      width: auto
      transform: scaleX(1)
      transition: 0.5s cubic-bezier(.01,1.04,.33,1.01)
      // animation-name: activeImg
      // animation-fill-mode: both
      // animation-duration: 1s
      // animation-timing-function: cubic-bezier(.76, 0, .24, 1)
    &--active > .main__btn__text
      color: #fff

    &--active > .main__btn__line
      width: 0
      opacity: 0

    &--hover  > .main__btn__img
      width: auto
      transform: scaleX(3)
      opacity: 0 
      transition: 0.5s cubic-bezier(.76, 0, .24, 1)

      
      // animation-name: goneImg
      // animation-fill-mode: both
      // animation-duration: 1s
      // animation-timing-function: cubic-bezier(.76, 0, .24, 1)
    &--hover > .main__btn__text
      color: #000
      flex-grow: 1

    &--hover 
      background-color: #fff

      img
        width: 100%
        transform: scaleX(2)
        opacity: 0

      .main__btn__line
        width: 100%
        opacity: 1

      .main__btn__text
        color: #000
    &--wordchang
      background-color: #fff
      .main__btn__img
        opacity: 0
      .main__btn__line
        width: 100%
        opacity: 1
      .main__btn__text
        color: #000
    &__img
      // animation-name: goneImg
      animation-duration: 0s
      animation-timing: cubic-bezier(.76, 0, .24, 1)
      transform-origin: left center
      // animation-fill-mode: forwards
      display: inline
      transform: scale(0)
      // overflow: hidden
      width: 0px
      // opacity: 0
      max-width: 80px
      +rwd-md
        max-width: 38px
      +rwd-sm
        max-width: 23px

    &__line
      width: 100%
      border-top: 3px solid #000
      height: 1px
      position: absolute
      top: 50%
      transform: translateY(-50%)
      margin-left: -22px
      +rwd-md
        margin-left: -15px

    &__text
      color: #000
      font-size: 36px
      font-weight: 900
      margin-left: 18px
      flex: 1 100%
      line-height: 36px
      text-align: right
      +rwd-md
        font-size: 18px
        line-height: 18px
        margin-left: 12px

      &--space
        margin-top: 8px
        letter-spacing: -0.05em
        +rwd-md
          margin-top: 3px

      &--en
        font-size: 24px
        +rwd-md
          font-size: 18px

.col
  padding: 0

.row > *
  padding: 0

.artistBlock
  cursor: pointer
  position: relative
  box-sizing: border-box
  background-repeat: no-repeat
  background-position: center center
  background-size: cover

  &--margin
    margin: 0
    background-color: #FFF
    +rwd-md
      padding: 0 7.5px

  &__img
    padding-top: 70%
    width: 100%

  &__data
    position: absolute
    // left: 9px
    padding: 0px 9px
    bottom: 6px

  &__label
    background: linear-gradient(92.43deg, #C0C0C0 0%, #9E9E9E 49.48%, #E9E9E9 75.52%, #838383 100%)
    min-width: 109px
    width: 100%
    max-width: 100%
    padding: 0px 8px 0px 3.5px
    margin-bottom: 4px

    &--bold
      font-weight: 800

.viewCircle
  position: fixed
  pointer-events: none
  z-index: 10
  // display: none
  &__block
    display: none
    position: relative
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%)
    width: 108px
    height: 108px
    border-radius: 50%

    &__text
      // position: absolute
      // top: 0
      // left: 0
      // transform: translate(-50%,-50%)
      font-size: 18px
      color: #ffffff

  &--show
    display: flex
    z-index: 100
    transform: scale(1)
    animation-name: circleAnimate
    animation-duration: 0.5s
    animation-timing-function: ease-out
    animation-fill-mode: forwards

  &--move
    border-radius: 50%
    display: flex
    // width: 20px
    // height: 20px
    transform: scale(0.2)
    background: #000
    animation-name: circleMovingAnimate
    animation-duration: 0.3s
    animation-timing-function: ease-out
    // .viewCircle__block
    //   display: none
    .viewCircle__block__text
      font-size: 0

  &--moveStop
    display: flex
    animation-name: circleMoveStopAnimate
    animation-duration: 0.3s
    transform: scale(1)
    animation-timing-function: ease-out
    animation-fill-mode: forwards

    .viewCircle__block__text
      font-size: 18px

  &--close
    display: none
    animation-name: circleCloseAnimate
    animation-duration: 0.5s
    animation-timing-function: ease-out
    animation-fill-mode: forwards
// animation-delay: 1s
@keyframes circleShowAnimate
  from
    transform: scale(0)
    opacity: 0
  to
    transform: scale(1)
    opacity: 1

@keyframes circleMovingAnimate
  from
    transform: scale(1)
  to
    transform: scale(0.2)

@keyframes circleMoveStopAnimate
  from
    transform: scale(0.2)
  to
    transform: scale(1)

@keyframes circleCloseAnimate
  0%
    transform: scale(1)
    opacity: 1
  100%
    transform: scale(0)
    opacity: 0
    display: none

@keyframes activeImg
  0%
    display: inline
    opacity: 0
    width: 0px
  100%
    opacity: 1
    width: 100%
    transform: scale(1)

    
@keyframes goneImg
  0%
    width: 100%
    opacity: 0
  100%
    opacity: 0
    overflow: hidden
    // transform: scale(0)
    width: 0%
    
</style>
