<template lang="pug">
.modal--center(ref='container')
  .artistModalOutside(v-if="groupData")
    .artist__btn.artist__btn--pre.flex-center
      img.artist__btn__img(:src="require(`@/assets/images/banner-arrow.svg`)" @click="$emit('toPrevGroup')")
    .artist__btn.artist__btn--next.flex-center
      img.artist__btn__img(:src="require(`@/assets/images/banner-arrow.svg`)" @click="$emit('toNextGroup')")
    .artistModalBack.d-none.d-lg-block
    .artist__label.d-none.d-lg-flex
      .artist__label__line
      .artist__label__text scroll
    .artist__close(@click="closeModal")
    .artistModal
      .artistModal__bg
      .artistModal__img(:style="{backgroundImage:`url(${$getPhotoUrl(groupData.photo)})`}")
        img.artistModal__imgblock
      .artistModal__title {{groupData.group_zh.name}}
      .artistModal__title--en(v-if="groupData.group_zh.name !== groupData.group_en.name") {{groupData.group_en.name}}
      .artistModal__content {{groupData[`group_${localeLang}`].description}}
      .artistModal__hr
      ArtistCollapse(v-if="groupData.artists.length > 0")
        template(v-slot:title) {{ $t('shared.artist') }}
        template(v-slot:content)
          .row.mb-3.p-3(v-for="(artist, index) in groupData.artists" :key="index")
            .col-2
              img(:src="$getPhotoUrl(artist.avatar)" class="artist-avatar")
            .col-10.d-flex.align-items-center
              h6.mb-0 {{ artist[`artist_${localeLang}`].name }}
            .col-12.position-relative
              article.artist-introduction.pt-3.artist-introduction-ellipse(v-html="getParsedIntro(artist[`artist_${localeLang}`].introduction)")
              .show-more-button-block.flex-center
                button.show-more-button.d-flex.align-items-center(@click.self="handleClearEllipse") {{ $t('shared.showMore') }}
                  img(src="../assets/images/icon/arrow-down.png" class="ps-1")
      .artistModal__hr(v-if="groupData.hasDesc")
      ArtistCollapse
        template(v-slot:title) {{ $t('shared.artwork') }}
        template(v-slot:content)
          .row
            .col-6.col-md-4(v-for="(work, index) in groupData.works" :key="index")
              .artBox(@click="toArtWork(work)")
                .artBox__img
                  img.img-fluid(:src="$getPhotoUrl(work.photo_1)")
                .artBox__content
                  .artBox__title.text-truncate {{ work[`work_${localeLang}`].title }}
                  .artBox__subtitle {{groupData[`group_${localeLang}`].name}}
            //- .col-6.col-md-4
            //-   .artBox
            //-     .artBox__img
            //-       img.img-fluid(:src="require(`@/assets/images/1.png`)")
            //-     .artBox__content
            //-       .artBox__title.text-truncate 稱作品名稱品名稱品名稱品名稱作品名稱品名稱品名稱品名稱作品名稱品名稱品名稱品名
            //-       .artBox__subtitle 作者名
            //- .col-6.col-md-4
            //-   .artBox
            //-     .artBox__img
            //-       img.img-fluid(:src="require(`@/assets/images/1.png`)")
            //-     .artBox__content
            //-       .artBox__title.text-truncate 作品名稱作品名稱品名稱品名稱品名
            //-       .artBox__subtitle 作者名
            //- .col-6.col-md-4
            //-   .artBox
            //-     .artBox__img
            //-       img.img-fluid(:src="require(`@/assets/images/1.png`)")
            //-     .artBox__content
            //-       .artBox__title.text-truncate 作品名稱作品名稱品名稱品名稱品名
            //-       .artBox__subtitle 作者名作者名作者名
      .artistModal__hr
</template>

<script>
import ArtistCollapse from "@/component/ArtistCollapse.vue";
import { getGroup } from "@/api/group";

export default {
  name: "ArtistModal",
  components: {
    ArtistCollapse,
  },
  data() {
    return {
      artistData: [],
      groupData: null,
    };
  },
  props: {
    groupId: String,
  },
  created() {
    this.getGroupData(this.groupId);
  },
  mounted() {
    document.addEventListener("click", this.closeModal);
  },
  methods: {
    getParsedIntro(string){
      return string.replaceAll("\n", "<br>");
    },
    async getGroupData(groupId) {
      let groupData = await getGroup(groupId);
      const filteredArtists = groupData.artists.filter(el => {
        return (el.artist_zh.name.trim() !== groupData.group_zh.name.trim()) || (el.artist_zh.introduction.trim() !== groupData.group_zh.description.trim())
      })
      groupData.hasDesc = false;
      filteredArtists.forEach((a) => {
        if (a.artist_zh.description !== "") groupData.hasDesc = true;
      });
      groupData.artists = filteredArtists;
      this.groupData = groupData;
    },
    closeModal: function (e) {
      if (
        e.target.className === "modal--center" ||
        e.target.className === "artist__close"
      ) {
        this.$emit("closeModal");
      }
    },
    toArtWork(work) {
      const slug = this.$getWorkSlug(work.work_en.title);
      this.$router.push({ path: `/artworks/${slug}` });
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeModal);
  },
  computed: {
    localeLang() {
      return this.$i18n.locale;
    },
  },
  setup() {
    function handleClearEllipse(event) {
      const parent = event.target.parentNode;
      parent.classList.add("d-none");
      const articleDOM = parent.previousElementSibling;
      articleDOM.classList.remove("artist-introduction-ellipse");
    }

    return {
      handleClearEllipse,
    };
  },
};
</script>

<style lang="sass" scoped>
.artist__close
  position: absolute
  right: -36px
  top: -1px
  z-index: 1
  height: 36px
  width: 36px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #000
  cursor: pointer
  +rwd-md
    top: 9px
    right: 11px
    height: 60px
    width: 60px

  &::before
    transform: rotate(45deg)
    top: 48%

  &::after
    transform: rotate(-45deg)
    top: 48%

  &:hover.artist__close::before
    width: 110%
    left: -2px
  // +rwd-md
  //   width: 40%
  //   left: 20px
  &:hover.artist__close::after
    width: 110%
    left: -2px
// +rwd-md
//   width: 36%
//   left: 20px

.artist__close::before,
.artist__close::after
  transition: 0.1s
  position: absolute
  content: ''
  width: 70%
  height: 2px
  background-color: #fff
  top: 48%
  left: 5px
  +rwd-md
    width: 36%
    left: 20px

.artist__btn
  z-index: 1
  transition: 1s
  position: absolute
  width: 53px
  height: 53px
  padding: 0 9px
  border-radius: 50%
  bottom: -46px
  cursor: pointer
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.51) 0%, #000000 100%)

  &:hover
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 1) 0%, #000000 100%)

  img
    width: 100%

  &--pre
    right: 38px
    +rwd-md
      right: 8px
      bottom: 83px

    img
      transform: rotate(180deg)

  &--next
    right: -22px
    +rwd-md
      right: 8px
      bottom: 20px

.artistModalOutside
  position: relative
  padding: 16px 14px 45px 1px
  border: solid 1px #000
  background-color: #fff
  max-height: 657px
  max-width: 585px
  min-width: 500px
  +rwd-md
    padding: 18px 11px 45px 1px
    width: 100%
    height: 100%
    max-height: none
    max-width: none
    min-width: initial

.artistModalBack
  position: absolute
  z-index: -1
  right: -22px
  bottom: -20px
  height: calc(100% + 61px)
  width: 100%
  max-height: 657px
  max-width: 585px
  background-color: #F0F0F0
  border: solid 1px #000

.artist__label
  display: flex
  align-items: center
  padding: 3.5px
  width: 210px
  height: 30px
  position: absolute
  left: -120px
  transform: rotate(-90deg)
  bottom: calc(0px + 89px)
  background: linear-gradient(92.06deg, #C0C0C0 0%, #9E9E9E 57.49%, #E9E9E9 87.75%, #838383 116.2%)
  border: 1px solid #000
  overflow: hidden

  &__text
    margin-left: auto
    font-size: 18px
    line-height: 18px
    font-weight: 400
    top: 5px
    right: 0
    position: absolute
    animation-name: textAnimate
    animation-duration: 2.5s
    animation-timing-function: ease-out
    animation-iteration-count: infinite
    animation-fill-mode: forwards

  &__line
    width: 14px
    height: 1px
    right: 45px
    border-top: solid 1px #000
    position: absolute
    animation-name: lineAnimate
    animation-duration: 2.5s
    animation-timing-function: ease-out
    animation-iteration-count: infinite
    animation-fill-mode: forwards

// animation-delay: 1s
@keyframes textAnimate
  50%
    right: 0
    opacity: 1
  70%
    right: 65%
    opacity: 1
  90%
    opacity: 1
  100%
    opacity: 0
    right: 65%

@keyframes lineAnimate
  0%
    opacity: 0
  10%
    opacity: 1
  20%
    opacity: 0
  30%
    opacity: 1
    width: 14px
  50%
    width: 75%
    right: 45px
    opacity: 1
  70%
    width: 14px
    right: 90%
    opacity: 0
  80%
    width: 14px
    opacity: 1
    right: 90%
  90%
    opacity: 0
  100%
    opacity: 1
    right: 90%


.artistModal
  background-color: #fff
  width: 100%
  height: 100%
  max-height: 596px
  max-width: 585px
  padding: 0 15px 0 20px
  overflow-y: scroll
  overflow-x: hidden
  +rwd-md
    max-height: none
    max-width: none

  &::-webkit-scrollbar-track
    background-color: #fff

  &::-webkit-scrollbar
    width: 2px
    background-color: #fff

  &::-webkit-scrollbar-thumb
    background-color: #000

  &::-webkit-scrollbar-corner
    background-color: transparent

  &__img
    border: solid 1px #000
    // position: relative
    // padding-top: 49.6268%
    // width: 100%
    // overflow: hidden
    cursor: pointer
    position: relative
    box-sizing: border-box
    background-repeat: no-repeat
    background-position: center center
    background-size: cover
    background-size: auto 100%
    background-color: black

  &__imgblock
    padding-top: 70%
    width: 100%
    +rwd-md
      padding-top: 80%
  // img
  //   width: 100%
  //   position: absolute
  //   top: 50%
  //   left: 50%
  //   transform: translate(-50%, -50%)
  //   object-fit: cover
  &__title
    font-size: 24px
    margin-top: 16px
    font-weight: 700
    line-height: 24px

  &__title--en
    font-size: 14px
    line-height: 14px
    margin-top: 8px
    margin-bottom: 4px

  &__content
    margin: 16px 0
    +rwd-md
      font-size: 12px

  &__hr
    width: 100%
    height: 1px
    border-bottom: solid 1px #000
    margin: 16px 0

.artistModal__collapse
  &__header
    cursor: pointer
    display: flex
    flex-direction: row

  &__icon
    padding: 0 9px

  &__title
    font-weight: 500

  &__content
    margin: 12px 0

.col
  padding: 0 8px

.artBox
  margin-top: 16px
  cursor: pointer
  position: relative
  box-sizing: border-box

  &__img
    position: relative
    padding-top: 100%
    width: 100%
    overflow: hidden

    img
      height: 100%
      width: 100%
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      object-fit: cover

  &__content
    position: absolute
    padding: 10px
    bottom: 0
    width: 100%
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.16) 38.65%, rgba(0, 0, 0, 0) 86.29%)

  &__title
    max-width: 100%
    color: #fff
    font-size: 12px
    font-weight: 500

  &__subtitle
    color: #fff
    font-size: 12px
    font-weight: 500

.modal--center
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1200
  width: 100%
  height: 100%
  padding: 0 40px
  display: flex
  justify-content: center
  align-items: center
  backdrop-filter: blur(5px)
  background-color: rgba(0, 0, 0, 0.37)
  +rwd-md
    align-items: flex-start
    padding: 64px 0 0 0

// artist modal block
.artist-avatar
  width: 70px
  height: 70px
  object-fit: cover
  background-position: center

.show-more-button-block
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9794292717086834) 35%, rgba(255, 255, 255, 1) 100%)
  position: absolute
  bottom: -20px
  width: 100%
  height: 50px

  .show-more-button
    background-color: inherit
    border: none

.artist-introduction-ellipse
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow: hidden
</style>
